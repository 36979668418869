import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import { useGroupVibrationOrder } from '../../modules/organisation/hooks/useGroupStandardData';
import Table from '../Table';
import { equipmentNameLink, lastDataProcessed, vibration, utilisation } from './columns';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

const columns = [
  lastDataProcessed,
  equipmentNameLink,
  vibration,
  utilisation,
];

function HighestVibratingEquipment() {
  const { t } = useTranslation();
  const groupVibrationOrder = useGroupVibrationOrder();
  const { filterAll } = useDeviceStatusFilter();
  const localisedColumns = useLocalisedColumns(columns);

  if(!groupVibrationOrder) return null;

  const {_state_: {loading, error} = {}, items = []} = groupVibrationOrder || {};
  return (
    <HomeCard
      header={t("user.Highest_Vibrating_Equipment")}
      headerLink={{to: '/', text: t('home.all-equipment'), onClick: filterAll }}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="device_id"
        loading={loading}
        columns={localisedColumns}
        condensed
      />
    </HomeCard>
  );
}

export default HighestVibratingEquipment;